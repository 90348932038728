import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { OnboardingStateModel } from './onboarding-state.models';
import { OnboardingStateActions } from './onboarding.actions';
import {
  BandmanagerRestApiCreateUniformDto,
} from '@digitale-menschen/bandmanager-rest-api/src/models/bandmanager-rest-api-create-uniform-dto';

const defaults: OnboardingStateModel = {
  bandName: null,
  bandLogo: null,
  countryId: null,
  bandUniform: null,
  email: null,
  password: null,
  username: null,
};

@State<OnboardingStateModel>({
  name: 'onboarding',
  defaults,
})
@Injectable()
export class OnboardingState {

  @Selector()
  static onboardingData(state: OnboardingStateModel): OnboardingStateModel {
    return state;
  }

  @Selector()
  static uniform(state: OnboardingStateModel): BandmanagerRestApiCreateUniformDto | null {
    return state.bandUniform;
  }

  @Action(OnboardingStateActions.SetUniform)
  setUniform(ctx: StateContext<OnboardingStateModel>, action: OnboardingStateActions.SetUniform) {
    ctx.setState(patch<OnboardingStateModel>({
      bandUniform: action.uniform,
    }));
  }

  @Action(OnboardingStateActions.SetUsername)
  setUsername(ctx: StateContext<OnboardingStateModel>, action: OnboardingStateActions.SetUsername) {
    ctx.setState(patch<OnboardingStateModel>({
      username: action.username,
    }));
  }

  @Action(OnboardingStateActions.SetEmailPassword)
  setEmailPassword(ctx: StateContext<OnboardingStateModel>, action: OnboardingStateActions.SetEmailPassword) {
    ctx.setState(patch<OnboardingStateModel>({
      email: action.email,
      password: action.password,
    }));
  }

  @Action(OnboardingStateActions.SetBandNameAndCountry)
  setBandNameAndCountry(ctx: StateContext<OnboardingStateModel>, action: OnboardingStateActions.SetBandNameAndCountry) {
    ctx.setState(patch<OnboardingStateModel>({
      bandName: action.bandName,
      countryId: action.countryId,
    }));
  }

  @Action(OnboardingStateActions.SetLogo)
  setLogo(ctx: StateContext<OnboardingStateModel>, action: OnboardingStateActions.SetLogo) {
    ctx.setState(patch<OnboardingStateModel>({
      bandLogo: action.logo,
    }));
  }
}
